import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Full Clean 4-4-4-4\\@80% 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`CrossFit Open WOD 13.3`}</strong></p>
    <p>{`12:00 amrap of:`}</p>
    <p>{`150 Wall Balls (20/14)`}</p>
    <p>{`90 Double Unders`}</p>
    <p>{`30 Ring Muscle Ups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Tomorrow is the last day to make up any Pre Open WODs so make sure
you get them in!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts February 22nd.  Register online at
Games.CrossFit.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      